import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useMemo } from 'react';
import flattenDeep from 'lodash/flattenDeep';

import { Container, EventHeader, Footer, Section, SectionTitle } from '../Common/Layout';
import { countItems, encodeUrl } from '../../common/helpers';
import { useBrandColor } from '../../common/Common/ThemeProvider';
import { useNavigation } from '../Common/useNavigation';
import ClaimRegistrationForm from './ClaimRegistrationForm';
import EventNotFound from '../../common/EventNotFound';
import GetClaimableRegistrationQuery from './GetClaimableRegistrationQuery';
import UI from '../../common/UI';
import useDocumentTitle from '../../common/useDocumentTitle';
import useProject from '../useProject';
import useQuery from '../../api/useQuery';
import useTimeSlotFormatter from '../../common/Tickets/useTimeSlotFormatter';

export interface ClaimRegistrationPageProps {
  eventId: string;
  registrationId: string;
  claimToken: string;
}

const ClaimRegistrationPage = ({
  eventId,
  registrationId,
  claimToken,
}: ClaimRegistrationPageProps) => {
  const { t } = useTranslation();
  const { r } = useNavigation();
  const formatTimeSlot = useTimeSlotFormatter();
  const project = useProject();
  const history = useHistory();

  const { data, error } = useQuery(
    GetClaimableRegistrationQuery,
    {
      variables: {
        event_id: eventId,
        registration_id: registrationId,
        claim_token: claimToken,
      },
      fetchPolicy: 'no-cache',
    },
  );

  const event = data?.event;
  const claimableRegistration = data?.claimableRegistration;
  const registration = claimableRegistration?.next_registration;
  const owner = claimableRegistration?.owner;
  const isClaimable = !!registration;

  useDocumentTitle([
    t('claim_your_ticket'),
    owner,
    event?.title,
    'Atleta',
  ]);

  useBrandColor(event?.brand_color);

  const handleSuccess = (email: string) => {
    const params = encodeUrl({
      email,
      justClaimed: true,
    });

    history.push(`${r('Login')}?${params}`);
  };

  const variant = useMemo(() => flattenDeep([
    registration?.promotion.title,
    registration?.time_slot && formatTimeSlot(
      registration?.time_slot,
    ),
    registration?.team?.title,
    countItems(registration?.upgrades.filter(
      (upgrade) => !upgrade.product.is_ticket_fee,
    ).map((upgrade) => (
      `${upgrade.product.title}${upgrade.product_variant ? ` (${upgrade.product_variant.title})` : ''}`
    )) || []),
  ].filter((value) => value)), [registration, formatTimeSlot]);

  if (error || !!event?.registrations_locked) {
    return <EventNotFound />;
  }

  if (!data || !project) {
    return (
      <UI.PageLoader />
    );
  }

  return (
    <Container>
      <UI.Div sc={{ pt: [3, 4] }}>
        <EventHeader event={event} />
      </UI.Div>

      <UI.HR sc={{ mx: [0, 4] }} />

      {isClaimable ? (
        <>
          <UI.GridContainer sc={{ gutter: 0.75 }}>
            <UI.Div sc={{ px: [2, 4] }}>
              <SectionTitle>
                {t('claim_your_ticket')}
              </SectionTitle>

              <UI.Div sc={{ color: 'gray.500' }}>
                <Trans
                  i18nKey="claim_your_ticket_description"
                  values={{ event: event.title, owner }}
                >
                  <UI.Strong />
                  <UI.Strong />
                </Trans>
              </UI.Div>
            </UI.Div>

            <Section spacing="md">
              <UI.FlexContainer sc={{ alignItems: 'center' }}>
                <UI.Badge sc={{ brand: 'secondary', size: 36, mr: 2 }}>
                  <UI.Icon sc={{ fontSize: 2 }}>
                    <UI.Icons.Ticket />
                  </UI.Icon>
                </UI.Badge>
                <UI.Div>
                  <UI.H4>
                    {registration.ticket.title}
                  </UI.H4>
                  <UI.Div sc={{ color: 'gray.500' }}>
                    <UI.Delimit>
                      {variant}
                    </UI.Delimit>
                  </UI.Div>
                </UI.Div>
              </UI.FlexContainer>
              <UI.Div sc={{ padding: 0, background: 'gray.50' }}>
                <ClaimRegistrationForm
                  registration={registration}
                  event={event}
                  owner={owner}
                  claimToken={claimToken}
                  onSuccess={handleSuccess}
                />
              </UI.Div>
            </Section>
          </UI.GridContainer>
        </>
      ) : (
        <UI.Warning>
          {t('ticket_already_being_claimed')}
        </UI.Warning>
      )}

      <Footer project={project} />
    </Container>
  );
};

export default ClaimRegistrationPage;
