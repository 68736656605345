import { Check, Copy, Share } from 'react-feather';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { NavItem, Section, SectionTitle } from '../../Common/Layout';
import { RegistrationSummary } from '../helpers';
import { assetUrl, encodeUrl, mergeUrlParams } from '../../../common/helpers';
import { useCopyLink } from '../../../common/Common/CopyLink';
import { useNavigation } from '../../Common/useNavigation';
import { useParticipant } from '../ParticipantProvider';
import SellableLabel from '../../../common/Common/SellableLabel';
import UI from '../../../common/UI';
import config from '../../../config';
import useLocale from '../../../common/useLocale';
import useProject from '../../useProject';
import useScroll from '../../../common/useScroll';
import useShare from '../../../common/useShare';

export interface UnassignedRegistrationsSectionProps {
  summary: RegistrationSummary;
}

const UnassignedRegistrationsSection = ({ summary }: UnassignedRegistrationsSectionProps) => {
  const { t } = useTranslation();
  const { r } = useNavigation();
  const { participant } = useParticipant();
  const { locale, formatNumber } = useLocale();
  const { scrollTo } = useScroll();
  const project = useProject();

  const groups: {
    assigned_count: number;
    total_count: number;
    ticket: { id: string; title: string; };
  }[] = [];

  summary.assigned_ticket_counts.forEach((counts) => {
    const ticketId = counts.ticket.id;
    const index = groups.findIndex((group) => group.ticket.id === ticketId);

    if (index > -1) {
      groups[index].assigned_count += counts.assigned_count;
      groups[index].total_count += counts.total_count;
    } else {
      groups.push({
        assigned_count: counts.assigned_count,
        total_count: counts.total_count,
        ticket: counts.ticket,
      });
    }
  });

  const isLocked = summary.event.registrations_locked;

  const inputRef = useRef<HTMLInputElement>();
  const buttonRef = useRef<HTMLButtonElement>();

  useEffect(() => {
    const input = inputRef.current;

    if (input) {
      const { paddingRight } = input.style;
      input.style.paddingRight = `${Math.max((buttonRef.current?.offsetWidth || 0) + 10, 0)}px`;

      return () => {
        input.style.paddingRight = `${paddingRight}px`;
      };
    }

    return () => {};
  });

  const { canShare, handleShare } = useShare({
    text: `${t('claim_your_ticket_for_event_here', { event: summary.event.title })}: ${summary.claim_url}`,
  });

  const [copy, copied] = useCopyLink(summary.claim_url);

  return (
    <UI.GridContainer sc={{ gutter: 0.75 }} id="UnassignedRegistrationsSection">
      <UI.Div sc={{ px: [2, 4] }}>
        <SectionTitle>
          {t('status')}
        </SectionTitle>
      </UI.Div>

      <Section>
        {groups.map((group) => (
          <NavItem
            to={mergeUrlParams({ registrations: { ticket: group.ticket.id } })}
            onClick={() => scrollTo('TicketsSection', { gutter: 1, duration: 200 })}
            title={group.ticket.title}
            key={group.ticket.id}
          >
            <UI.GridContainer sc={{ columns: '1fr fit-content(160px) 40px', alignVertical: 'center', gutter: 0.5 }}>
              <UI.Div sc={{ fontWeight: 500 }} style={{ minWidth: 0, display: 'flex' }}>
                <SellableLabel style={{ minWidth: 0 }}>
                  <UI.Truncate>
                    <UI.Icon>
                      <UI.Icons.Ticket />
                    </UI.Icon>
                    {' '}
                    {group.ticket.title}
                  </UI.Truncate>
                </SellableLabel>
              </UI.Div>
              <UI.Div sc={{ small: true, color: 'gray.500', noWrap: true, textAlign: 'right' }}>
                {group.total_count - group.assigned_count > 0 ? t('n_remaining', {
                  count: group.total_count - group.assigned_count,
                  value: formatNumber(group.total_count - group.assigned_count),
                }) : t('complete')}
              </UI.Div>
              <ProgressBar count={group.assigned_count} capacity={group.total_count} />
            </UI.GridContainer>
          </NavItem>
        ))}

        {summary.event.active && !isLocked && (
          <UI.FormGrid sc={{ gutter: 0.75, background: 'gray.50' }}>
            <UI.Div>
              <UI.Legend sc={{ fontWeight: 500, mb: 1 }}>
                {t('registration_link')}
              </UI.Legend>
              {t('registration_link_description')}
            </UI.Div>
            <UI.InputGroup>
              <UI.InputWrapper>
                <UI.Input
                  defaultValue={summary.claim_url}
                  readOnly
                  onFocus={(event) => event.target.select()}
                  ref={inputRef}
                  sc={{ color: 'gray.500' }}
                  aria-label={t('registration_link')}
                />
                <UI.Button
                  onClick={() => copy()}
                  sc={{ blank: true, noWrap: true }}
                  ref={buttonRef}
                  style={{ borderLeft: '1px solid rgba(0, 0, 0, .075)' }}
                >
                  <UI.Icon>
                    {copied ? <Check /> : <Copy />}
                  </UI.Icon>
                  {' '}
                  {t('copy')}
                </UI.Button>
              </UI.InputWrapper>
            </UI.InputGroup>

            {canShare && (
              <UI.Button onClick={handleShare} sc={{ brand: 'secondary' }}>
                <UI.Icon>
                  <Share />
                </UI.Icon>
                {' '}
                {t('share_link')}
              </UI.Button>
            )}
          </UI.FormGrid>
        )}

        {participant && participant.is_charity && summary.event.enable_fundraising && (
          <UI.Div sc={{ background: 'gray.50' }}>
            <UI.A
              href={config.supporta.signUpUrl[locale]}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={assetUrl('images/logo-supporta-mono.svg')}
                width={80}
                alt="Supporta"
              />
            </UI.A>
            {!participant.charity && (
              <UI.Div sc={{ color: 'gray.500' }}>
                {t('charity_fundraising_description')}
                <br />
                <UI.Link to={`${r('Charity', { projectId: project.id, participantId: summary.participant.id })}?${encodeUrl({ event: summary.event.id, token: summary.view_token })}}`}>
                  {t('configure_charity')}
                </UI.Link>
              </UI.Div>
            )}
            {participant.charity && (
              <UI.Div>
                {t('fundraising_pages_will_be_created_for')}
                {' '}
                <UI.Link
                  to={`${r('Charity', { projectId: project.id, participantId: summary.participant.id })}?${encodeUrl({ event: summary.event.id, token: summary.view_token })}}`}
                  sc={{ noWrap: true }}
                  style={{ fontWeight: 500 }}
                >
                  {participant.charity.title}
                </UI.Link>
              </UI.Div>
            )}
          </UI.Div>
        )}
      </Section>
    </UI.GridContainer>
  );
};

export const ProgressBar = ({ count, capacity, secondary = 0, tooltip }: any) => {
  const [progress, setProgress] = useState(0);
  const [extra, setExtra] = useState(0);

  const calculatedCapacity = Math.max(capacity, count + secondary);
  const calculatedProgress = Math.min(100, (count / calculatedCapacity) * 100);
  const calculatedExtra = Math.min(100, (secondary / calculatedCapacity) * 100);

  useEffect(() => {
    const timer = window.setTimeout(() => {
      setProgress(calculatedProgress);
      setExtra(calculatedExtra);
    }, 100);

    return () => window.clearTimeout(timer);
  }, [calculatedProgress, calculatedExtra]);

  return (
    <UI.TooltipContainer style={{ width: 40, height: 8 }}>
      {tooltip && (
        <UI.Tooltip sc={{ alignH: 'center', alignV: 'top', small: true }}>
          {tooltip}
        </UI.Tooltip>
      )}
      <UI.Div
        sc={{ background: 'gray.200' }}
        style={{
          height: '100%', width: '100%', borderRadius: '4px', overflow: 'hidden', display: 'flex',
        }}
      >
        <UI.Div
          sc={{ background: 'success' }}
          style={{
            height: '100%',
            width: `${progress}%`,
            transition: 'all 0.25s ease-in-out',
          }}
        />
        <UI.Div
          sc={{ background: 'info' }}
          style={{
            height: '100%',
            width: `${extra}%`,
            transition: 'all 0.25s ease-in-out',
          }}
        />
      </UI.Div>
    </UI.TooltipContainer>
  );
};

export default UnassignedRegistrationsSection;
