import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useMemo } from 'react';
import flattenDeep from 'lodash/flattenDeep';

import { GetClaimableRegistrationsQuery as GetClaimableRegistrations } from '__generated__/graphql';

import { Container, EventHeader, Footer, NavItem, Section, SectionTitle } from '../Common/Layout';
import { countItems, mergeUrlParams } from '../../common/helpers';
import { useBrandColor } from '../../common/Common/ThemeProvider';
import { useNavigation } from '../Common/useNavigation';
import EventNotFound from '../../common/EventNotFound';
import GetClaimableRegistrationsQuery from './GetClaimableRegistrationsQuery';
import UI from '../../common/UI';
import useDocumentTitle from '../../common/useDocumentTitle';
import useProject from '../useProject';
import useQuery from '../../api/useQuery';
import useTimeSlotFormatter from '../../common/Tickets/useTimeSlotFormatter';
import useUrlState from '../../common/useUrlState';

type ClaimableRegistration = GetClaimableRegistrations['claimableRegistrations']['registrations'][0];

export interface ClaimRegistrationPageProps {
  projectId: string;
  eventId: string;
  participantId: string;
  claimToken: string;
}

const ClaimRegistrationsPage = ({ projectId, eventId, participantId, claimToken }: ClaimRegistrationPageProps) => {
  const { t } = useTranslation();
  const { r } = useNavigation();
  const project = useProject();
  const history = useHistory();

  const [urlState] = useUrlState({
    parse: (params, { string }) => ({
      claim_id: string(params.claim_id),
    }),
  });

  const { data, error } = useQuery(
    GetClaimableRegistrationsQuery,
    {
      variables: {
        event_id: eventId,
        participant_id: participantId,
        claim_token: claimToken,
        claim_id: urlState.claim_id,
      },
      onCompleted: (data) => {
        // See if a reserved registration is present in the data.
        // If so, redirect to the registration's claim page.
        history.replace({ search: '' });

        const claimableRegistration = data.claimableRegistrations.registrations.filter((registration) => (
          registration.id === urlState.claim_id && registration.next_registration && registration.claim_token
        ))[0];

        if (claimableRegistration) {
          history.push(r('ClaimRegistration', {
            projectId,
            participantId,
            eventId,
            registrationId: claimableRegistration.next_registration.id,
            claimToken: claimableRegistration.claim_token,
          }));
        }
      },
    },
  );

  const event = data?.claimableRegistrations.event;
  const owner = data?.claimableRegistrations.owner;
  const claimableRegistrations = data?.claimableRegistrations.registrations;
  const isLocked = !!event?.registrations_locked;

  useDocumentTitle([
    t('claim_your_ticket'),
    owner,
    event?.title,
    'Atleta',
  ]);

  useBrandColor(event?.brand_color);

  if (error) {
    return <EventNotFound />;
  }

  if (!data || !project) {
    return (
      <UI.PageLoader />
    );
  }

  return (
    <Container>
      <UI.Div sc={{ pt: [3, 4] }}>
        <EventHeader event={event} />
      </UI.Div>

      <UI.HR sc={{ mx: [0, 4] }} />

      {!isLocked && claimableRegistrations.length > 0 && (
        <UI.GridContainer sc={{ gutter: 0.75 }}>
          <UI.Div sc={{ px: [2, 4] }}>
            <SectionTitle>
              {t('claim_your_ticket')}
            </SectionTitle>

            <UI.Div sc={{ color: 'gray.500' }}>
              <Trans
                i18nKey="choose_ticket_description"
                values={{ event: event.title, owner }}
              >
                <UI.Strong />
                <UI.Strong />
              </Trans>
            </UI.Div>
          </UI.Div>

          <Section>
            {claimableRegistrations.map((claimableRegistration) => (
              <ClaimableRegistrationCard
                claimableRegistration={claimableRegistration}
                key={claimableRegistration.id}
              />
            ))}
          </Section>
        </UI.GridContainer>
      )}

      {(isLocked || claimableRegistrations.length === 0) && (
        <Section>
          <UI.Div>{t('no_tickets_to_claim')}</UI.Div>
        </Section>
      )}

      <Footer project={project} />
    </Container>
  );
};

export interface ClaimableRegistrationCardProps {
  claimableRegistration: ClaimableRegistration;
}

const ClaimableRegistrationCard = ({
  claimableRegistration,
}: ClaimableRegistrationCardProps) => {
  const { t } = useTranslation();
  const formatTimeSlot = useTimeSlotFormatter();

  const [urlState] = useUrlState({
    parse: (params, { string }) => ({
      claim_id: string(params.claim_id),
    }),
  });

  const loading = urlState.claim_id === claimableRegistration.id;

  const variant = useMemo(() => flattenDeep([
    claimableRegistration.promotion.title,
    claimableRegistration.time_slot && formatTimeSlot(
      claimableRegistration.time_slot,
    ),
    claimableRegistration.team?.title,
    ...countItems(claimableRegistration.upgrades.filter(
      (upgrade) => !upgrade.product.is_ticket_fee,
    ).map((upgrade) => (
      `${upgrade.product.title}${upgrade.product_variant ? ` (${upgrade.product_variant.title})` : ''}`
    )) || []),
  ].filter((value) => value)), [claimableRegistration, formatTimeSlot]);

  return (
    <NavItem
      to={mergeUrlParams({ claim_id: claimableRegistration.id })}
      icon={loading ? <UI.Loader sc={{ size: 19 }} /> : (!claimableRegistration.available ? <></> : undefined)}
      title={[claimableRegistration.ticket.title, ...variant].join(' • ')}
    >
      <UI.FlexContainer sc={{ alignItems: 'center' }}>
        <UI.Badge sc={{ brand: 'secondary', size: 36, mr: 2 }}>
          <UI.Icon sc={{ fontSize: 2 }}>
            <UI.Icons.Ticket />
          </UI.Icon>
        </UI.Badge>
        <UI.Div>
          <UI.H4 sc={{ color: !claimableRegistration.available ? 'gray.500' : undefined }}>
            {claimableRegistration.ticket.title}
          </UI.H4>
          {variant.length > 0 && (
            <UI.Div sc={{ color: 'gray.500' }}>
              <UI.Delimit>
                {variant}
              </UI.Delimit>
            </UI.Div>
          )}
        </UI.Div>
      </UI.FlexContainer>
      {!claimableRegistration.available && (
        <UI.Warning sc={{ mt: 2 }}>
          {t('ticket_already_being_claimed')}
        </UI.Warning>
      )}
    </NavItem>
  );
};

export default ClaimRegistrationsPage;
