import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { Breadcrumbs, Section } from '../../../Common/Layout';
import { useBrandColor } from '../../../../common/Common/ThemeProvider';
import { useNavigation } from '../../../Common/useNavigation';
import { useParticipant } from '../../ParticipantProvider';
import ExtraInfoSection from './ExtraInfoSection';
import GetOrderForDashboardQuery from './GetOrderForDashboardQuery';
import InvoiceDetailsSection from './InvoiceDetailsSection';
import OrderSummary from './OrderSummary';
import UI from '../../../../common/UI';
import useDocumentTitle from '../../../../common/useDocumentTitle';
import useProject from '../../../../common/useProject';

export interface OrderPageProps {
  participantId: string;
  eventId: string;
  orderId: string;
  token: string;
}

const OrderPage = ({ participantId, eventId, orderId, token }: OrderPageProps) => {
  const { t } = useTranslation();
  const { r } = useNavigation();
  const { impersonating } = useParticipant();
  const project = useProject();

  const { data } = useQuery(
    GetOrderForDashboardQuery,
    {
      variables: {
        order_id: orderId,
        token,
      },
    },
  );

  const order = data?.order;

  useDocumentTitle([
    order?.participant.full_name,
    order?.event.title,
    'Atleta',
  ]);

  useBrandColor(order?.event.brand_color);

  const showExtraInfo = order?.participant_fields.length > 0 || order?.stand_alone_upgrades.filter(
    (upgrade) => upgrade.participant_fields.length > 0 || upgrade.product_variant,
  ).length > 0;

  if (!data) {
    return <UI.PageLoader />;
  }

  return (
    <UI.FadeIn>
      <UI.GridContainer>
        <Breadcrumbs hideHome={impersonating}>
          <UI.Link
            to={r('Registrations', {
              eventId,
              projectId: project.id,
              participantId,
              token,
            })}
          >
            {order.event.title}
          </UI.Link>
          <UI.Link
            to={r('Orders', {
              eventId,
              projectId: project.id,
              participantId,
              token,
            })}
          >
            {t('invoices')}
          </UI.Link>
          <UI.Strong>
            {t('order_n', { number: order.invoice_id })}
          </UI.Strong>
        </Breadcrumbs>

        <Section spacing="md" title={t('order_details')}>
          <OrderSummary order={order} />
        </Section>

        {showExtraInfo && (
          <ExtraInfoSection order={order} />
        )}

        <InvoiceDetailsSection order={order} />
      </UI.GridContainer>
    </UI.FadeIn>
  );
};

export default OrderPage;
