import { Download } from 'react-feather';
import { startOfDay } from 'date-fns';
import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useModal } from 'react-modal-hook';
import { useTranslation } from 'react-i18next';

import { Breadcrumbs, EventHeader, NavItem, Section } from '../../Common/Layout';
import { RegistrationSummary } from '../helpers';
import { useBrandColor } from '../../../common/Common/ThemeProvider';
import { useGuard } from '../Guard';
import { useNavigation } from '../../Common/useNavigation';
import { useParticipant } from '../ParticipantProvider';
import { useRegistrationsUrlState } from './helpers';
import GetRegistrationSummaryForDashboardQuery from './GetRegistrationSummaryForDashboardQuery';
import GetSupportaCampaignsQuery from './GetSupportaCampaignsQuery';
import MessagesSection from './MessagesSection';
import RegistrationsSection from './RegistrationsSection';
import SupportaSection from './SupportaSection';
import TeamsSection from './TeamsSection';
import UI from '../../../common/UI';
import UnassignedRegistrationsSection from './UnassignedRegistrationsSection';
import useDocumentTitle from '../../../common/useDocumentTitle';
import useLocale from '../../../common/useLocale';
import useProject from '../../useProject';
import useQuery from '../../../api/useQuery';

export interface RegistrationsPageProps {
  participantId: string;
  eventId: string;
  token: string;
}

const RegistrationsPage = ({
  participantId, eventId, token,
}: RegistrationsPageProps) => {
  const { t } = useTranslation();
  const { r } = useNavigation();
  const history = useHistory();
  const { participant } = useParticipant();

  const [urlState] = useRegistrationsUrlState();

  const { data, refetch } = useQuery(
    GetRegistrationSummaryForDashboardQuery,
    {
      variables: {
        participant: participantId,
        event: eventId,
        token,
        registrations_limit: urlState.registrations.limit || 10,
        registration_filters: {
          search: urlState.registrations.search,
          tickets: urlState.registrations.ticket ? [urlState.registrations.ticket] : null,
          teams: urlState.registrations.team && urlState.registrations.team !== 'no_team'
            ? [urlState.registrations.team] : null,
          has_team: urlState.registrations.team === 'no_team' ? false : null,
          assignment_statuses: urlState.registrations.assignment_statuses,
        },
        teams_search: urlState.teams.search,
        teams_limit: urlState.teams.limit,
        include_filtered_teams: !!urlState.teams.search,
      },
      onError: (error) => {
        if (error.graphQLErrors?.length > 0) {
          // Redirect only in case of a normal authentication/validation error.
          history.replace(r('Portal'));
        }
      },
    },
  );

  const summary = data?.registrationSummary;

  useDocumentTitle([
    summary?.participant.full_name,
    summary?.event.title,
    'Atleta',
  ]);

  useBrandColor(summary?.event.brand_color);

  const participantRef = useRef(participant);

  // Refetch summary after logging in
  useEffect(() => {
    if (data && participant !== participantRef.current) {
      refetch();
      participantRef.current = participant;
    }
  }, [data, participant, refetch]);

  const order = summary?.orders.filter((order) => order.id === urlState.orderId)[0];

  const showSupportaSection = summary?.event.enable_fundraising;

  const { data: supportaData } = useQuery(
    GetSupportaCampaignsQuery,
    {
      variables: {
        participant: participantId,
        event: eventId,
        token,
      },
      skip: !showSupportaSection,
    },
  );

  if (!data) {
    return <UI.PageLoader />;
  }

  const hasUnassignedRegistrations = summary.assigned_ticket_counts.some(
    (group) => group.total_count - group.assigned_count > 0,
  );

  const nothingToSee = summary.registrations_count
    + summary.teams.length
    + summary.messages.length === 0
    && !showSupportaSection;

  return (
    <UI.FadeIn>
      <UI.GridContainer>
        <Header summary={summary} />

        {urlState.orderId && (
          <UI.Div sc={{ px: [2, 4] }}>
            <UI.Success>
              <UI.Strong sc={{ block: true }}>
                {t('order_confirmation_title')}
              </UI.Strong>
              {order && t('order_confirmation_description', { number: order.invoice_id })}
            </UI.Success>
          </UI.Div>
        )}

        {summary.registrations_count > 0 && (
          <>
            {hasUnassignedRegistrations && summary.registrations_count > 1 && (
              <UnassignedRegistrationsSection summary={summary} />
            )}

            <RegistrationsSection summary={summary} />
          </>
        )}

        {summary.teams.length > 0 && (
          <TeamsSection summary={summary} />
        )}

        {summary.messages.length > 0 && (
          <MessagesSection messages={summary.messages} />
        )}

        {showSupportaSection && (
          <SupportaSection
            summary={summary}
            campaigns={supportaData?.registrationSummary.supporta_campaigns}
          />
        )}

        {nothingToSee && (
          <Section>
            <UI.Div sc={{ muted: true }}>
              {t('no_registrations_yet')}
            </UI.Div>
          </Section>
        )}
      </UI.GridContainer>
    </UI.FadeIn>
  );
};

interface HeaderProps {
  summary: RegistrationSummary;
}

const Header = ({ summary }: HeaderProps) => {
  const { t } = useTranslation();
  const { r } = useNavigation();
  const { formatDate, parseDate, formatNumber } = useLocale();
  const { guard } = useGuard(summary.participant);
  const { impersonating } = useParticipant();
  const project = useProject();

  const { event, participant } = summary;

  const ticketsCount = summary.registrations_count;

  const showUpgradesOption = summary.has_upgrades_available;
  const showInvoicesOption = summary.orders_count > 0;
  const showExportParticipantsOption = ticketsCount > 1 && summary.is_company;
  const showDownloadTicketsOption = event.enable_tickets && ticketsCount > 0 && ticketsCount <= 100;

  const showOptions = showUpgradesOption
    || showInvoicesOption
    || showExportParticipantsOption
    || showDownloadTicketsOption;

  const [openExportModal, closeExportModal] = useModal(() => (
    <ExportParticipantsModal url={summary.export_participants_url} onClose={closeExportModal} />
  ), []);

  const ticketsAvailableFrom = event.tickets_available_from
    ? parseDate(summary.event.tickets_available_from, { timezone: 'UTC' })
    : null;

  return (
    <>
      <Breadcrumbs hideHome={impersonating}>
        <UI.Strong>{event.title}</UI.Strong>
      </Breadcrumbs>
      <EventHeader event={event} />
      {showOptions && (
        <Section secondary>
          {showUpgradesOption && (
            <NavItem
              to={r('Upgrades', {
                projectId: project.id,
                participantId: participant.id,
                eventId: event.id,
                token: summary.view_token,
              })}
              title={t('order_extras')}
              alignVertical="center"
              sc={{ py: 2 }}
            >
              <UI.Span sc={{ fontWeight: 500 }}>
                {t('order_extras')}
              </UI.Span>
            </NavItem>
          )}
          {showInvoicesOption && (
            <NavItem
              to={r('Orders', {
                projectId: project.id,
                participantId: participant.id,
                eventId: event.id,
                token: summary.view_token,
              })}
              title={t('invoices')}
              alignVertical="center"
              sc={{ py: 2 }}
            >
              <UI.FlexContainer sc={{ alignItems: 'center', justifyContent: 'space-between' }}>
                <UI.Span sc={{ fontWeight: 500 }}>
                  {t('invoices')}
                </UI.Span>
                <UI.Badge
                  sc={{
                    background: 'gray.250', outline: false, color: 'gray.500', size: 24, ml: 2,
                  }}
                  style={{ marginTop: -1, marginBottom: -1 }}
                >
                  {formatNumber(summary.orders_count)}
                </UI.Badge>
              </UI.FlexContainer>
            </NavItem>
          )}
          {showExportParticipantsOption && (
            <NavItem
              onClick={() => guard(openExportModal)}
              title={t('export_participants')}
              icon={<Download />}
              alignVertical="center"
              sc={{ py: 2 }}
            >
              <UI.Span sc={{ fontWeight: 500 }} style={{ minWidth: 0 }}>
                <UI.Truncate>
                  {t('export_participants')}
                </UI.Truncate>
              </UI.Span>
            </NavItem>
          )}
          {showDownloadTicketsOption && (
            <NavItem
              href={event.tickets_available && summary.tickets_url}
              target="_blank"
              rel="noopener noreferrer"
              title={ticketsCount > 1 ? t('download_tickets') : t('download_ticket')}
              icon={<Download />}
              alignVertical="center"
              sc={{ py: 2 }}
            >
              <UI.FlexContainer sc={{ alignItems: 'center', justifyContent: 'space-between' }}>
                <UI.Span sc={{ fontWeight: 500, muted: !event.tickets_available }} style={{ minWidth: 0 }}>
                  <UI.Truncate>
                    {ticketsCount > 1 ? t('download_tickets') : t('download_ticket')}
                  </UI.Truncate>
                </UI.Span>
                <UI.Span sc={{ flex: {} }}>
                  {!event.tickets_available && ticketsAvailableFrom && (
                    <UI.Span sc={{ ml: 1 }}>
                      <UI.Label
                        sc={{ background: 'gray.250', color: 'gray.600', noWrap: true }}
                        style={{ fontSize: '0.75em' }}
                      >
                        {t('from_date', { date: formatDate(ticketsAvailableFrom, {
                          format: startOfDay(ticketsAvailableFrom).getTime() === ticketsAvailableFrom.getTime()
                            ? 'display_date_without_year'
                            : 'display_date_time_without_year',
                        }) })}
                      </UI.Label>
                    </UI.Span>
                  )}
                  {ticketsCount > 1 && (
                    <UI.Badge
                      sc={{
                        background: 'gray.250', outline: false, color: 'gray.500', size: 24, ml: 2,
                      }}
                      style={{ marginTop: -1, marginBottom: -1 }}
                    >
                      {formatNumber(ticketsCount)}
                    </UI.Badge>
                  )}
                </UI.Span>

              </UI.FlexContainer>
            </NavItem>
          )}
        </Section>
      )}
      <UI.HR sc={{ mx: [0, 4] }} />
    </>
  );
};

const ExportParticipantsModal = ({ url, onClose }: { url: string; onClose: () => void; }) => {
  const { t } = useTranslation();

  return (
    <UI.Modal close={onClose}>
      <UI.GridContainer>
        <UI.Div>
          <UI.H4 sc={{ mb: 2 }}>
            {t('export_participants')}
          </UI.H4>
          {t('export_participants_description')}
        </UI.Div>

        <UI.GridContainer sc={{ columns: '1fr 1fr', gutter: 0.5 }}>
          <UI.AButton href={`${url}?format=csv`} onClick={onClose}>
            <UI.Icon>
              <Download />
            </UI.Icon>
            {' '}
            {t('csv')}
          </UI.AButton>
          <UI.AButton href={`${url}?format=excel`} onClick={onClose}>
            <UI.Icon>
              <Download />
            </UI.Icon>
            {' '}
            {t('excel')}
          </UI.AButton>
        </UI.GridContainer>
      </UI.GridContainer>
    </UI.Modal>
  );
};

export default RegistrationsPage;
