import {
  ArrowUpRight, Check, ChevronDown, ChevronRight, ChevronUp, Eye, Home, LogOut, ThumbsDown, ThumbsUp, User,
} from 'react-feather';
import { Dispatch, ReactNode, SetStateAction, useContext, useRef, useState } from 'react';
import { useForm } from 'react-form-state-manager';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import { Locale } from '__generated__/graphql';

import { Event, Participant } from '../Dashboard/helpers';
import { EventInfo } from '../../frontend/Common/Layout';
import { GridAlignment, Size, UIProps } from '../../theme/mixins';
import { Project } from '../useProject';
import { Routes, useNavigation } from './useNavigation';
import { ThemeContext } from '../../common/Common/ThemeProvider';
import { assetUrl, resizedImageUrl } from '../../common/helpers';
import { defaultSecondary } from '../../theme/variables';
import { useAuth, useLogout } from '../../common/Auth/useAuth';
import AddReviewMutation from '../../common/AddReviewMutation';
import EditCurrentUserMutation from './EditCurrentUserMutation';
import LanguageSelector from '../../common/Common/LanguageSelector';
import SC from '../../common/UI/SC';
import UI from '../../common/UI';
import config from '../../config';
import useLocale from '../../common/useLocale';
import useMutation from '../../api/useMutation';
import useOnClickOutside from '../../common/useOnClickOutside';

interface SectionProps {
  title?: ReactNode;
  count?: number;
  secondary?: boolean;
  dashed?: boolean;
  id?: string;
  intro?: ReactNode;
  active?: boolean;
  spacing?: Size;
  sc?: UIProps;
  children: ReactNode;
}

export const Section = ({
  title, count, secondary = false, dashed = false, id, intro, active, spacing = 'sm', sc, children,
}: SectionProps) => (
  <SectionContainer dashed={dashed} id={id} sc={sc}>
    {(title || intro) && (
      <UI.Div>
        {title && <SectionTitle count={count}>{title}</SectionTitle>}
        {intro && <UI.Div sc={{ color: 'gray.500' }}>{intro}</UI.Div>}
      </UI.Div>
    )}

    <UI.Card
      sc={{
        hasPanels: true,
        background: secondary ? 'gray.150' : undefined,
        outline: secondary ? 'gray.150' : undefined,
        spacing,
        active,
        transitionPadding: true,
      }}
    >
      {children}
    </UI.Card>
  </SectionContainer>
);

interface SectionTitleProps {
  children: ReactNode;
  loading?: boolean;
  count?: number;
}

export const SectionTitle = ({ children, loading, count }: SectionTitleProps) => {
  const { formatNumber } = useLocale();

  return (
    <UI.H3 sc={{ fontSize: 3, fontWeight: 500, flex: { alignItems: 'center' } }}>
      <UI.Span sc={{ mr: 1.5 }}>
        {children}
      </UI.Span>
      {loading && (
        <UI.Loader sc={{ brand: 'gray.500' }} />
      )}
      {!loading && typeof count !== 'undefined' && (
        <UI.Small>
          <UI.Badge sc={{ color: 'gray.500' }}>
            {formatNumber(count)}
          </UI.Badge>
        </UI.Small>
      )}
    </UI.H3>
  );
};

interface NavItemProps extends SC<UIProps> {
  to?: string;
  href?: string;
  onClick?: () => void;
  id?: string;
  target?: string;
  rel?: string;
  title?: string;
  icon?: ReactNode;
  alignVertical?: GridAlignment;
  children: ReactNode;
}

export const NavItem = ({
  to, href, onClick, id, target, rel, title, icon, alignVertical, sc, children,
}: NavItemProps) => (
  <UI.LinkBlock to={to} href={href} onClick={onClick} id={id} target={target} rel={rel} title={title} sc={sc}>
    <UI.GridContainer sc={{ columns: '1fr 18px', gutter: 0.5, alignVertical }}>
      <UI.Div style={{ minWidth: 0 }}>
        {children}
      </UI.Div>
      <UI.Div sc={{ my: -0.25 }}>
        {(to || href || onClick) ? (
          <NavItemIcon>
            {typeof icon === 'undefined' ? <ChevronRight /> : icon}
          </NavItemIcon>
        ) : (
          <UI.Icon sc={{ muted: true }}>
            {typeof icon === 'undefined' ? <ChevronRight /> : icon}
          </UI.Icon>
        )}
      </UI.Div>
    </UI.GridContainer>
  </UI.LinkBlock>
);

const NavItemIcon = styled(UI.Icon)`
  ${({ theme }) => css`
    font-size: 1.125em;
    color: ${theme.colors.gray[500]};
    transition: all 0.1s ease-in-out;
  `}
`;

interface SectionState {
  [key: string]: boolean;
}

interface UseDropdownSectionStateOptions {
  initialState?: SectionState | boolean;
  onOpen?: (key: string) => void;
  onClose?: (key: string) => void;
}

/**
 * Provides state and handlers to control <DropdownSection />.
 */
export const useDropdownSectionState = ({
  initialState = {}, onOpen, onClose,
}: UseDropdownSectionStateOptions = {}) => {
  const defaultKey = 'default';

  const [open, setOpen] = useState<SectionState>(
    typeof initialState === 'boolean' ? { [defaultKey]: initialState } : initialState,
  );

  return {
    isOpen: (key: string = defaultKey) => !!open[key],
    openSection: (key: string = defaultKey) => {
      setOpen((open) => ({ ...open, [key]: true }));
      onOpen?.(key);
    },
    closeSection: (key: string = defaultKey) => {
      setOpen((open) => ({ ...open, [key]: false }));
      onClose?.(key);
    },
    toggleSection: (key: string = defaultKey) => {
      const isOpen = !open[key];

      setOpen({ ...open, [key]: isOpen });

      if (isOpen) {
        onOpen?.(key);
      } else {
        onClose?.(key);
      }
    },
  };
};

const DropdownSectionContent = styled(UI.Div)`
  ${({ theme }) => css`
    background: ${theme.colors.gray[50]};
    border-bottom-left-radius: ${theme.borderRadiuses.xl}px;
    border-bottom-right-radius: ${theme.borderRadiuses.xl}px;

    > * + * {
      border-top: 1px solid rgba(0, 0, 0, 0.1);
    }
  `}
`;

interface DropdownSectionrops {
  preview: ReactNode;
  body: ReactNode;
  title?: string;
  open?: boolean;
  onToggle: Dispatch<SetStateAction<boolean>>;
  id?: string;
}

export const DropdownSection = ({
  preview, body, title, open, onToggle, id,
}: DropdownSectionrops) => (
  <Section spacing={open ? 'md' : 'sm'} id={id} active={open}>
    <NavItem
      title={title}
      onClick={body ? () => onToggle((open) => !open) : undefined}
      icon={body ? (!open ? <ChevronDown /> : <ChevronUp />) : null}
    >
      {preview}
    </NavItem>
    <UI.Div
      sc={{ padding: 0 }}
      style={{
        opacity: !open ? 0 : undefined,
        transition: 'all 0.15s ease-in-out',
      }}
      id={id ? `${id}_Body` : undefined}
    >
      <UI.AnimateHeight isVisible={open}>
        <DropdownSectionContent>
          {body}
        </DropdownSectionContent>
      </UI.AnimateHeight>
    </UI.Div>
  </Section>
);

interface SectionContainerProps extends SC<UIProps> {
  dashed?: boolean;
}

const SectionContainer = styled(UI.GridContainer)<SectionContainerProps>`
  ${({ dashed = false, theme }) => css`
    grid-gap: ${theme.gutter * 0.5}px;
    padding: 0 ${theme.gutter * 0.5}px;

    @media ${theme.devices.md} {
      padding: 0 ${theme.gutter}px;
    }

    ${UI.H4} {
      font-weight: 600;

      ${UI.Icon} svg {
        stroke-width: 2.5;
      }
    }

    ${dashed && css`
      > ${UI.Card} > * + * {
        border-top: 2px dashed ${theme.colors.gray[200]};
      }
    `}

    > ${UI.Card} > *:hover ${NavItemIcon} {
      margin-left: 3px;
      color: ${theme.colors.gray[800]};
    }

    > ${UI.Card} {
      border-radius: ${theme.borderRadiuses.xl}px;

      > :first-child {
        border-top-left-radius: ${theme.borderRadiuses.xl}px;
        border-top-right-radius: ${theme.borderRadiuses.xl}px;
      }

      > :last-child {
        border-bottom-left-radius: ${theme.borderRadiuses.xl}px;
        border-bottom-right-radius: ${theme.borderRadiuses.xl}px;
      }
    }
  `}
`;

const BreadcrumbsContainer = styled(UI.Div)`
  ${({ theme }) => css`
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    font-size: ${14 / 16}rem;
    padding: ${theme.gutter * 0.5}px;
    margin: 0;

    @media ${theme.devices.md} {
      padding: ${theme.gutter}px 0;
      margin: 0 ${theme.gutter}px;
    }
  `}
`;

interface BreadcrumbsProps {
  hideHome?: boolean;
  children?: ReactNode | ReactNode[];
}

export const Breadcrumbs = ({ hideHome, children }: BreadcrumbsProps) => {
  const { t } = useTranslation();
  const { r } = useNavigation();

  // The children of the Delimit component have to be a flattened array for it to work
  const breadcrumbs = [
    ...!hideHome ? [
      <UI.Link to={r('Portal')} key="home">
        {t('home')}
      </UI.Link>,
    ] : [],
    ...Array.isArray(children) ? children : [children],
  ];

  return (
    <BreadcrumbsContainer>
      <UI.Delimit
        delimiter={<UI.Icon sc={{ muted: true, mx: 1 }}><ChevronRight /></UI.Icon>}
      >
        {breadcrumbs}
      </UI.Delimit>
    </BreadcrumbsContainer>
  );
};

export const Container = styled(UI.Div)`
  ${({ theme }) => css`
    min-width: 360px;
    max-width: ${theme.breakpoints.md}px;
    margin: 0 auto;
    display: grid;
    grid-gap: ${theme.gutter * 0.75}px;

    @media ${theme.devices.md} {
      grid-gap: ${theme.gutter}px;
    }

    > ${SectionContainer}:first-child {
      margin-top: ${theme.gutter * 0.75}px;

      @media ${theme.devices.md} {
        margin-top: ${theme.gutter}px;
      }
    }

    > ${UI.HR} {
      margin: 0;
    }
  `}
`;

interface FillRequiredFieldsProps {
  onClick: () => void;
}

export const FillRequiredFields = ({ onClick }: FillRequiredFieldsProps) => {
  const { t } = useTranslation();

  return (
    <UI.FadeIn>
      <UI.Warning>
        <UI.A
          onClick={onClick}
          sc={{ secondary: true }}
          style={{ fontWeight: 500 }}
          role="button"
        >
          {t('fill_required_fields')}
          {' '}
          <UI.Icon>
            <ArrowUpRight />
          </UI.Icon>
        </UI.A>
      </UI.Warning>
    </UI.FadeIn>
  );
};

interface HeaderProps {
  user: { first_name?: string } | null;
  title?: string;
  impersonatedUsername?: string;
}

export const Header = ({ user, title, impersonatedUsername }: HeaderProps) => {
  const { t } = useTranslation();
  const { r } = useNavigation();
  const [logout] = useLogout();

  const theme = useContext(ThemeContext);
  const branded = theme.options.secondary !== defaultSecondary;

  const [showingUserNav, setShowingUserNav] = useState(false);
  const userNavRef = useRef<HTMLDivElement>();
  useOnClickOutside(userNavRef, () => setShowingUserNav(false));

  const isLoggedIn = !!user;
  const showLoginLink = !isLoggedIn;
  const loginLink = r('Login');

  return (
    <NavBar>
      <Container sc={{ px: [2, 4] }} style={{ height: 43 }}>
        <UI.FlexContainer
          sc={{ justifyContent: 'space-between', alignItems: 'center' }}
          style={{ width: '100%', height: '100%' }}
        >
          <UI.Link
            to={r('Portal')}
            sc={{ color: 'gray.800', flex: { alignItems: 'center' } }}
            style={{ minWidth: 0, opacity: 1 }}
            disabled={!!impersonatedUsername}
          >
            <UI.FlexContainer
              sc={{
                mr: 1.5,
                background: 'secondary.600',
                borderRadius: 'md',
                alignItems: 'center',
                justifyContent: 'center',
                padding: 1.25,
              }}
              style={{ width: 30, height: 30, transition: 'all 0.5s ease-in-out' }}
            >
              {/* eslint-disable max-len */}
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 129.84 119.08" style={{ width: '100%', height: '100%' }}>
                <g>
                  <g>
                    <path d="M57.57,48.53,116,67.92a1.24,1.24,0,0,0,1.56-.79,1.23,1.23,0,0,0-.11-1L86.55,12.58a24.85,24.85,0,1,0-29,36Z" fill={branded ? 'white' : '#808ac4'} style={{ opacity: branded ? 0.4 : 1, transition: 'all 0.5s ease-in-out' }} />
                    <path d="M88.05,76l-46,40.92a1.23,1.23,0,0,0,.82,2.15H104.7A24.84,24.84,0,1,0,92.47,72.79,25.75,25.75,0,0,0,88.05,76Z" fill={branded ? 'white' : '#64c2a1'} style={{ opacity: branded ? 0.6 : 1, transition: 'all 0.5s ease-in-out' }} />
                    <path d="M49.06,88.73,36.63,28.42a1.23,1.23,0,0,0-1.4-1,1.27,1.27,0,0,0-.91.66L3.44,81.6A24.84,24.84,0,1,0,49.68,94.19,24.45,24.45,0,0,0,49.06,88.73Z" fill={branded ? 'white' : '#1fbff2'} style={{ opacity: branded ? 0.8 : 1, transition: 'all 0.5s ease-in-out' }} />
                  </g>
                </g>
              </svg>
              {/* eslint-enable max-len */}
            </UI.FlexContainer>
            <UI.Div style={{ minWidth: 0 }}>
              <UI.Truncate sc={{ fontWeight: 500 }} style={{ lineHeight: 1.1 }}>
                {title || t('dashboard')}
              </UI.Truncate>
            </UI.Div>
          </UI.Link>
          <UserNav>
            <UI.Div>
              {impersonatedUsername && (
                <UI.Span sc={{ color: 'gray.500', fontWeight: 500 }}>
                  <UI.Icon>
                    <Eye />
                  </UI.Icon>
                  {' '}
                  {impersonatedUsername}
                </UI.Span>
              )}
              {!impersonatedUsername && isLoggedIn && (
                <UI.A
                  onClick={() => setShowingUserNav((showing) => !showing)}
                  sc={{ noWrap: true, muted: showingUserNav, fontWeight: 500 }}
                  role="button"
                >
                  <UI.Icon>
                    <User />
                  </UI.Icon>
                  {' '}
                  {user.first_name}
                  {' '}
                  <UI.Caret sc={{ color: 'gray.400' }} />
                </UI.A>
              )}
              {!impersonatedUsername && showLoginLink && (
                <UI.Link to={loginLink}>
                  <UI.Icon>
                    <User />
                  </UI.Icon>
                  {' '}
                  {t('log_in')}
                </UI.Link>
              )}
            </UI.Div>
            {showingUserNav && (
              <UI.Card ref={userNavRef}>
                <UI.Link to={Routes.Portal} onClick={() => setShowingUserNav(false)}>
                  <UI.Icon sc={{ mr: 1.5 }}>
                    <Home />
                  </UI.Icon>
                  {t('home')}
                </UI.Link>
                <UI.Link to={Routes.Account} onClick={() => setShowingUserNav(false)}>
                  <UI.Icon sc={{ mr: 1.5 }}>
                    <User />
                  </UI.Icon>
                  {t('edit_account')}
                </UI.Link>
                <UI.A onClick={() => { logout(); setShowingUserNav(false); }} role="button">
                  <UI.Icon sc={{ mr: 1.5 }}>
                    <LogOut />
                  </UI.Icon>
                  {t('log_out')}
                </UI.A>
              </UI.Card>
            )}
          </UserNav>
        </UI.FlexContainer>
      </Container>
    </NavBar>
  );
};

const NavBar = styled(UI.Div)`
  ${({ theme }) => css`
    background: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(3px);
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    padding: ${theme.gutter / 3}px 0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 500;
    font-size: ${13 / 14}em;
    min-height: 60px;

    ${UI.ProfilePic} {
      text-shadow: 0 1px 1px rgba(0, 0, 0, .1);
      border: none;
      box-shadow: none;
      opacity: 0.9;

      &:hover {
        opacity: 1;
      }
    }

    ${Container} {
      position: relative;
    }
 `}
`;

const UserNav = styled(UI.Div)`
  ${({ theme }) => css`
    position: relative;

    > ${UI.Div}:first-child {
      a {
        color: ${theme.colors.gray[800]};

        &:not([href]):hover {
          color: ${theme.colors.gray[600]};
        }

        &[href]:hover {
          color: ${theme.colors.link};
        }
      }
    }

    ${UI.Card} {
      position: absolute;
      top: 30px;
      right: 0;
      width: 200px;
      border-radius: ${theme.borderRadiuses.lg}px;
      padding: 4px;
      box-shadow: ${theme.shadows.sm}, ${theme.shadows.lg}, 0 0 0 3px rgba(0, 0, 0, .01);

      > * + * {
        margin-top: 1px;
      }

      a {
        font-weight: 500;
        padding: ${theme.gutter / 3}px ${theme.gutter / 2}px !important;
        color: ${theme.colors.gray[700]};
        display: block;

        &:hover {
          background: ${theme.colors.gray[75]};
          border-radius: ${theme.borderRadiuses.sm}px;
          color: ${theme.colors.gray[800]};
        }
      }
    }
  `}
`;

export const HeaderPlaceholder = styled(UI.Div)`
  background: white;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  height: 60px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 500;
`;

export interface FooterProps {
  project?: Project;
  participant?: Participant,
}

export const Footer = ({ project, participant }: FooterProps) => {
  const { user } = useAuth();
  const location = useLocation();

  const [editLocale] = useMutation(
    EditCurrentUserMutation,
  );

  const handleChangeLocale = !user ? undefined : (locale: Locale) => {
    editLocale({
      variables: {
        input: {
          locale,
        },
      },
    });
  };

  return (
    <>
      {project && (
        <>
          <UI.HR sc={{ mx: [0, 4] }} />
          <FeedbackForm
            project={project}
            participant={participant}
            meta={JSON.stringify({ userAgent: navigator.userAgent })}
            key={location.pathname} // Reset form on navigation.
          />
        </>
      )}
      <UI.HR sc={{ mx: [0, 4] }} />
      <UI.FlexContainer sc={{ justifyContent: 'space-between', alignItems: 'center', px: [2, 4], pb: 4 }}>
        <LanguageSelector onChangeLocale={handleChangeLocale} />
        <a href={config.baseUrl} target="_blank" rel="noopener noreferrer">
          <img
            src={assetUrl('images/logo-symbol-black.svg')}
            alt="Atleta"
            height={16}
            style={{ opacity: 0.3 }}
          />
        </a>
      </UI.FlexContainer>
    </>
  );
};

export interface FeedbackFormProps {
  project: Project;
  participant?: Participant | null;
  meta?: string | null;
}

export const FeedbackForm = ({
  project,
  participant,
  meta,
}: FeedbackFormProps) => {
  const { t } = useTranslation();

  const [addReview, { loading }] = useMutation(
    AddReviewMutation,
  );

  const [rating, setRating] = useState<number | null>(null);
  const [comment, setComment] = useState('');
  const [showComment, setShowComment] = useState(false);

  const form = useForm({
    values: {
      comment,
      meta,
    },
  });

  const addPositiveReview = () => {
    setShowComment(true);

    if (rating !== 1) {
      setRating(1);

      addReview({
        variables: {
          input: {
            project: { id: project.id },
            participant: participant ? { id: participant.id } : null,
            rating: 1,
            ...form.values,
            url: window.location.href,
            token: participant?.access_token,
          },
        },
      });
    }
  };

  const addNegativeReview = () => {
    setShowComment(true);

    if (rating !== 0) {
      setRating(0);

      addReview({
        variables: {
          input: {
            project: { id: project.id },
            participant: participant ? { id: participant.id } : null,
            rating: 0,
            ...form.values,
            url: window.location.href,
            token: participant?.access_token,
          },
        },
      });
    }
  };

  const addComment = () => {
    setComment(form.values.comment);

    addReview({
      variables: {
        input: {
          project: { id: project.id },
          participant: participant ? { id: participant.id } : null,
          rating,
          ...form.values,
          url: window.location.href,
          token: participant?.access_token,
        },
      },
    });
  };

  const cancel = () => {
    setShowComment(false);
  };

  return (
    <UI.Div sc={{ px: [2, 4] }}>
      <UI.Div sc={{ textAlign: 'center', color: 'gray.600', small: true }}>
        {t('how_do_you_rate_this_page')}
      </UI.Div>
      <UI.Div sc={{ textAlign: 'center', mt: 1 }}>
        <UI.Button
          onClick={() => addPositiveReview()}
          sc={{
            brand: rating === 1 ? 'success' : 'gray.150',
            color: rating === 1 ? 'white' : 'success',
            muted: rating === 0,
            mr: 1,
          }}
          aria-label={t('thumbs_up')}
        >
          <UI.Icon>
            <ThumbsUp />
          </UI.Icon>
        </UI.Button>
        <UI.Button
          onClick={() => addNegativeReview()}
          sc={{
            brand: rating === 0 ? 'error' : 'gray.150',
            color: rating === 0 ? 'white' : 'error',
            muted: rating === 1,
          }}
          aria-label={t('thumbs_down')}
        >
          <UI.Icon>
            <ThumbsDown />
          </UI.Icon>
        </UI.Button>
      </UI.Div>
      <UI.AnimateHeight isVisible={showComment}>
        <UI.Card sc={{ mt: 2, textAlign: 'left' }}>
          <UI.Form onSubmit={() => addComment()}>
            <UI.FormGrid sc={{ gutter: 0.5 }}>
              <UI.InputGroup>
                <UI.InputLabel htmlFor="Comment">
                  {t('feedback_message_label')}
                </UI.InputLabel>
                <UI.TextArea {...form.textarea('comment')} rows={1} id="Comment" />
              </UI.InputGroup>
              <UI.Div>
                <UI.Button
                  type="submit"
                  sc={{ brand: 'secondary', loading }}
                  disabled={!form.values.comment || form.values.comment === comment}
                >
                  {(!comment || form.values.comment !== comment || loading)
                    ? t('send_feedback')
                    : (
                      <>
                        <UI.Icon>
                          <Check />
                        </UI.Icon>
                        {' '}
                        {t('feedback_sent')}
                      </>
                    )}
                </UI.Button>
                {' '}
                <UI.Button onClick={() => cancel()}>
                  {t('close')}
                </UI.Button>
              </UI.Div>
            </UI.FormGrid>
          </UI.Form>
        </UI.Card>
      </UI.AnimateHeight>
    </UI.Div>
  );
};

interface EventHeaderProps {
  event: Event;
}

export const EventHeader = ({ event }: EventHeaderProps) => {
  const { locale } = useLocale();

  return (
    <UI.GridContainer sc={{ columns: '60px 1fr', gutter: 0.5, alignVertical: 'center', px: [2, 4] }}>
      <UI.Div>
        <UI.A href={`${event.checkout_url}?locale=${locale}`}>
          <UI.ProfilePic
            sc={{
              background: event.logo ? 'white' : undefined,
              size: 60,
            }}
          >
            {event.logo && (
              <img
                src={resizedImageUrl(event.logo.url, 300)}
                alt={event.title}
              />
            )}
            {!event.logo && (
              <UI.Span><UI.Span>{event.title.charAt(0)}</UI.Span></UI.Span>
            )}
          </UI.ProfilePic>
        </UI.A>
      </UI.Div>
      <UI.Div>
        <UI.H2 sc={{ fontSize: 2, mb: 0.5 }}>
          <UI.A href={`${event.checkout_url}?locale=${locale}`} sc={{ color: 'gray.800' }}>
            {event.title}
          </UI.A>
        </UI.H2>
        <EventInfo event={event} />
      </UI.Div>
    </UI.GridContainer>
  );
};

export default undefined;
