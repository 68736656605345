import { ArrowDown, Search, X, XCircle } from 'react-feather';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { RegistrationSummary } from '../helpers';
import { Section, SectionTitle } from '../../Common/Layout';
import { useRegistrationsUrlState } from './helpers';
import TeamDetails from './TeamDetails';
import UI from '../../../common/UI';

export interface TeamsSectionProps {
  summary: RegistrationSummary;
  perPage?: number;
}

const TeamsSection = ({ summary, perPage = 5 }: TeamsSectionProps) => {
  const { t } = useTranslation();

  const searchRef = useRef<HTMLInputElement>();
  const [urlState, setUrlState] = useRegistrationsUrlState();
  const [loading, setLoading] = useState(false);

  const showMore = () => {
    setLoading(true);

    setUrlState((params) => ({
      ...params,
      teams: {
        ...params.teams,
        limit: params.teams.limit + perPage,
      },
    }), true);
  };

  const resetState = () => {
    setUrlState((params) => ({
      ...params,
      teams: {
        search: null,
        limit: null,
      },
    }), true);
  };

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setLoading(true);

    setUrlState((params) => ({
      ...params,
      teams: {
        search: event.target.value || null,
        limit: null,
      },
    }), true);
  };

  const resetSearch = () => {
    if (searchRef.current) {
      searchRef.current.value = '';
    }

    resetState();
  };

  useEffect(() => {
    setLoading(false);
  }, [summary]);

  const filteredTeamIds = summary.filtered_teams?.map((team) => team.id);
  const filteredTeams = summary.teams.filter((team) => !filteredTeamIds || filteredTeamIds.includes(team.id));
  const teams = filteredTeams.slice(0, urlState.teams.limit);

  return (
    <UI.GridContainer sc={{ gutter: 0.75 }}>
      <UI.Div sc={{ px: [2, 4] }}>
        <SectionTitle
          count={summary.teams.length > 1 ? summary.filtered_teams_count : undefined}
          loading={loading}
        >
          {t(summary.teams.length === 1 ? 'team' : 'teams')}
        </SectionTitle>

        {summary.teams.length > 1 && (
          <UI.Div sc={{ mt: 3 }}>
            <UI.InputWrapper>
              <UI.Span>
                <UI.Icon>
                  <Search />
                </UI.Icon>
              </UI.Span>
              <UI.DebouncedInput
                type="text"
                value={urlState.teams.search}
                onChange={handleSearch}
                placeholder={t('search_placeholder')}
                delay={500}
                ref={searchRef}
                sc={{ px: 5 }}
              />
              {urlState.teams.search && (
                <UI.Span>
                  <UI.A onClick={() => resetSearch()} sc={{ color: 'gray.400' }}>
                    <UI.Icon>
                      <XCircle />
                    </UI.Icon>
                  </UI.A>
                </UI.Span>
              )}
            </UI.InputWrapper>
          </UI.Div>
        )}
      </UI.Div>

      <UI.GridContainer sc={{ gutter: 0.5, loading }}>
        {teams.map((team) => (
          <TeamDetails team={team} summary={summary} key={team.id} />
        ))}

        {teams.length === 0 && (
          <Section secondary>
            <UI.FlexContainer sc={{ alignItems: 'center' }} style={{ minHeight: 80 }}>
              <UI.Div sc={{ textAlign: 'center', width: 100, color: 'gray.600' }}>
                {t('no_results')}
              </UI.Div>
            </UI.FlexContainer>
          </Section>
        )}

        {summary.filtered_teams_count > perPage && (
          <UI.GridContainer
            sc={{
              columns: urlState.teams.limit > perPage ? 'fit-content(150px) 2fr' : '1fr',
              gutter: 0.5,
              px: [2, 4],
            }}
          >
            {urlState.teams.limit > perPage && (
              <UI.Button
                onClick={() => resetState()}
                sc={{ outline: true, borderRadius: 'xl', size: 'lg', fontSize: 1 }}
              >
                <UI.Icon>
                  <X />
                </UI.Icon>
                {' '}
                {t('hide')}
              </UI.Button>
            )}
            <UI.Button
              onClick={() => showMore()}
              disabled={urlState.teams.limit >= summary.filtered_teams_count || loading}
              sc={{ outline: true, borderRadius: 'xl', size: 'lg', fontSize: 1 }}
            >
              <UI.Icon>
                <ArrowDown />
              </UI.Icon>
              {' '}
              {t('show_more')}
            </UI.Button>
          </UI.GridContainer>
        )}
      </UI.GridContainer>
    </UI.GridContainer>
  );
};

export default TeamsSection;
